<template>
  <card>
    <template slot="header">
      <p class="card-header-title">Login methods</p>
    </template>

    <template slot="body">
      <div class="columns is-multiline">
        <div class="column is-12" data-provider="password">
          <p class="has-margin-bottom-100">
            Simplify your login to fixed.net by linking your accounts from the
            following providers:
          </p>

          <apart
            v-for="p in providers"
            :key="p.provider().providerId"
            is-hoverable
          >
            <p>
              <b-icon
                :icon="p.icon.code"
                :pack="p.icon.pack"
                size="is-small"
                class="has-margin-right-25"
              />
              <span>{{ p.label }}</span>
            </p>

            <confirm
              v-if="p.linked && p.provider().providerId !== 'password'"
              :message="
                `Are you sure you want to unlink your ${p.label} account?`
              "
              :action="`Unlink ${p.label}`"
              @confirmed="ctaClick(p)"
            >
              <a
                :class="{
                  'is-loading': processing === p.provider().providerId
                }"
                role="button"
                class="button is-rounded has-text-danger"
              >
                <span>{{ ctaLabel(p) }}</span>
              </a>
            </confirm>

            <a
              v-else
              :class="{
                'has-text-danger': p.linked,
                'is-loading': processing === p.provider().providerId
              }"
              role="button"
              class="button is-rounded"
              @click="ctaClick(p)"
            >
              <b-icon v-if="ctaIcon(p)" :icon="ctaIcon(p)" size="is-small" />
              <span>{{ ctaLabel(p) }}</span>
            </a>
          </apart>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "LoginMethods",
  data() {
    return {
      processing: ""
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.auth.currentUser,
      providers: state => state.auth.providers
    }),
    ...mapGetters({
      linkedProviders: "auth/linkedProviders"
    })
  },

  methods: {
    ctaLabel(p) {
      switch (p.provider().providerId) {
        case "password":
          return p.linked ? "Change" : "Set";
        default:
          return p.linked ? "Unlink" : "Link";
      }
    },
    ctaIcon(p) {
      return p.provider().providerId !== "password" && !p.linked
        ? "link"
        : null;
    },
    ctaClick(p) {
      if (!this.processing) {
        this.processing = p.provider().providerId;
        if (p.provider().providerId === "password") {
          this.setPassword(p).finally(() => {
            this.processing = "";
          });
        } else {
          this.$store
            .dispatch(`auth/${p.linked ? "unlinkProvider" : "linkProvider"}`, {
              providerId: p.provider().providerId
            })
            .then(result => {
              this.$toast.open({
                message: result.message,
                position: "is-bottom",
                queue: false
              });
            })
            .catch(error => {
              this.$toast.open({
                message: error.message,
                position: "is-bottom",
                type: "is-danger",
                queue: false
              });
            })
            .finally(() => {
              this.processing = "";
            });
        }
      }
    },
    setPassword(p) {
      return new Promise(resolve => {
        const modal = this.$modal.open({
          parent: this,
          component: () => import("./_passwordModal"),
          hasModalCard: true,
          width: 500,
          canCancel: false,
          props: {
            hasPassword: p.linked
          },
          events: {
            close: () => {
              return resolve();
            },
            set: () => {
              this.$store.commit("auth/linkProvider", {
                providerId: p.provider().providerId
              });
              modal.close();
              return resolve();
            }
          }
        });
      });
    }
  }
};
</script>
